@import '../../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.greeting {
  /* Font */
  font-weight: var(--fontWeightBlack);
  font-size: 24px;
  line-height: 24px;

  padding: 3px 0 3px 0;
  margin-bottom: 20px;
  margin-top: 64px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 56px;
    padding: 5px 0 3px 0;
    margin-top: 24px;
  }
}

.accountLinksWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.inbox {
  /* Font */
  color: var(--marketplaceColor);
  font-size: 20px;
  margin-top: 45px;
  margin-bottom: 13px;
}

.dataLinksWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;
}

.dataItem {
  /* Font */
  color: var(--marketplaceColor);
  font-size: 20px;
  margin-top: 45px;
  margin-bottom: 13px;
  font-weight: var(--fontWeightBlack);
}

.arrow {
  margin-left: 8px;
  margin-bottom: 7px;
  width: 15px;
  height: 15px;
}

.dropdownContent {
  margin-left: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  & .dropdownItem {
    font-size: 20px;
    line-height: 24px;

    padding: 3px 0 3px 0;
    margin-bottom: 20px;
    margin-top: 64px;
  }
}
