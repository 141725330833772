.secondaryTopbar {
  width: 100%;
  height: 62px;
  background-color: gainsboro;
  font-family: var(--fontFamily);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;

  /* & .desktopLeft, .desktopRight{
    display: flex;
    column-gap: 20px;

    @media(max-width: 900px){
      display: none;
    }
  }
  & .mobileLeft, .mobileRight{
    display: none;
    column-gap: 20px;

    @media(max-width: 900px){
      display: flex;
    }
  } */
}

.desktopLeft,
.desktopRight {
  display: flex;
  column-gap: 20px;
}

@media (max-width: 900px) {
  .desktopLeft,
  .desktopRight {
    display: none;
  }
}

.mobileLeft,
.mobileRight {
  display: flex;
  column-gap: 20px;
}

@media (min-width: 901px) {
  .mobileLeft,
  .mobileRight {
    display: none;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .desktopLeft,
  .desktopRight {
    display: none;
  }
  .mobileLeft,
  .mobileRight {
    display: flex;
  }
}

@media (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {
  .desktopLeft,
  .desktopRight {
    display: none;
  }
  .mobileLeft,
  .mobileRight {
    display: flex;
  }
}

@media only screen and (max-width: 900px),
  only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .desktopLeft,
  .desktopRight {
    display: none;
  }
  .mobileLeft,
  .mobileRight {
    display: flex;
    align-items: center;
  }
}

@media only screen and (min-width: 901px),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .desktopLeft,
  .desktopRight {
    display: flex;
  }
  .mobileLeft,
  .mobileRight {
    display: none;
  }
}

.listItemButton {
  background: var(--marketplaceColor);
  color: white;
  padding: 2px 11px;
  font-size: 13px;
  border-radius: 5px;
}

.loginSignupWrapper {
  font-weight: bold;
  display: flex;
  gap: 4px;
}

/* Tooltip styles - only for right side buttons */
.rightButtonTooltip {
  position: relative;
  display: inline-block;

  & span:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.tooltipContent {
  visibility: hidden;
  position: absolute;
  z-index: 10;
  top: calc(100% + 10px); 
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--marketplaceColor); 
  color: white;
  text-align: center;
  padding: 12px 20px;
  border-radius: 0;
  font-size: 16px;
  opacity: 0;
  transition: opacity 0.2s ease, visibility 0s linear 0.2s;
  white-space: nowrap;
  font-weight: normal;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Active state controlled by React */
.tooltipContent.active {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s ease, visibility 0s;
}

/* Arrow for tooltip */
.tooltipContent::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--marketplaceColor) transparent;
}

.tooltipLinks {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.tooltipLink {
  color: white;
  text-decoration: none;
  padding: 5px 0;
  width: 100%;
  text-align: left;
  transition: background-color 0.2s;
}

.tooltipLink:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
